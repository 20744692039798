<template>
  <div class="container1">
    <el-container style="background: #fff">
      <el-aside
        class="aside"
        width="240px"
        style="border-right: 1px solid #efefef"
      >
        <div class="aside-content">
          <h2 class="title">账户管理</h2>
          <div class="account-tree">
            <account-tree @check="checkChange"></account-tree>
          </div>
        </div>
      </el-aside>
      <el-container>
        <el-header style="border-bottom: 1px solid #efefef">
          <el-form
            inline
            :model="form"
            class="form-inline"
            size="small"
          >
            <el-form-item>
              <el-input
                v-model="form.accountName"
                placeholder="输入关键字搜索"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="form.tagIds"
                clearable
                placeholder="标签选择"
                multiple
              >
                <el-option
                  v-for="(item, index) in tags"
                  :label="item.name"
                  :value="item.id"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="form.accountDomain"
                placeholder="主域名"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="form.accountHide"
                clearable
                placeholder="显示/隐藏"
              >
                <el-option
                  label="显示"
                  :value="false"
                ></el-option>
                <el-option
                  label="隐藏"
                  :value="true"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                @click="onSearch"
              >
                搜 索</el-button
              >
              <el-button @click="reset"> 重 置</el-button>
            </el-form-item>
          </el-form>
        </el-header>
        <el-main>
          <div class="button-groups">
            <el-button
              size="small"
              :disabled="!multipleSelection.length"
              @click="showBatchSetting = true"
            >
              批量设置
            </el-button>
            <el-button
              size="small"
              :disabled="!multipleSelection.length"
              @click="hideCredentialAccount(true)"
            >
              隐藏（在这个mcc+个号里）
            </el-button>
            <el-button
              size="small"
              :disabled="!multipleSelection.length"
              @click="hideCredentialAccount(false)"
            >
              显示（在这个mcc+个号里）
            </el-button>
            <el-button
              size="small"
              :disabled="!multipleSelection.length"
              @click="setSetupAccount()"
            >
              一键配置转化
            </el-button>
          </div>
          <el-table
            :data="list"
            border
            :height="tableHeight"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              width="55"
              fixed="left"
            ></el-table-column>
            <el-table-column
              width="250"
              prop="adaccount.name"
              label="广告账户"
              fixed="left"
            >
              <template slot-scope="{ row }">
                <div>
                  <el-tooltip
                    v-if="row.invalid"
                    class="item"
                    effect="dark"
                    :content="row.error"
                    placement="top-end"
                  >
                    <span class="el-icon-error"></span>
                  </el-tooltip>
                  {{ row.adaccount.name }}
                  <p class="link">
                    <el-link @click="f_goChangename(row)"> 修改名称<i class="el-icon-edit el-icon--right" /> </el-link>
                    <el-link
                      v-if="!row.adaccount.canManagerClient"
                      @click="createAd(row.adaccount)"
                    >
                      创建广告<i class="el-icon-plus el-icon--right" />
                    </el-link>
                  </p>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              min-width="120"
              prop="adaccount.id"
              label="账号id"
              fixed="left"
            ></el-table-column>
            <el-table-column
              prop="rootMccId"
              width="120"
              label="根mcc"
            ></el-table-column>
            <el-table-column
              width="180"
              prop="credential.thirdUser.thirdUserEmail"
              label="个号邮箱"
            >
              <template slot-scope="{ row }">
                <div class="table-tags">
                  {{ row.credential.thirdUser.thirdUserEmail }}
                  (在此个号:{{ row.hide ? '隐藏' : '显示' }})
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="adaccount.currency"
              width="100"
              label="币种"
            ></el-table-column>
            <el-table-column
              prop="adaccount.timezoneName"
              width="120"
              label="时区"
            ></el-table-column>
            <el-table-column
              width="180"
              label="标签"
            >
              <template slot-scope="{ row }">
                <div
                  v-if="row.adaccount.tags && row.adaccount.tags.length"
                  class="table-tags"
                >
                  <el-tag
                    v-for="(item, i) in row.adaccount.tags"
                    size="medium"
                    type="primary"
                    :key="i"
                  >
                    {{ item.name }}
                  </el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              width="220"
              label="主域名"
            >
              <template slot-scope="{ row }">
                <div
                  v-if="row.adaccount.domains && row.adaccount.domains.length"
                  class="table-tags"
                >
                  <el-tag
                    v-for="(item, i) in row.adaccount.domains"
                    size="mini"
                    type="warning"
                    effect="dark"
                    :key="i"
                  >
                    {{ item }}
                  </el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              width="200"
              prop="adaccount.pullTime"
              label="拉取时间"
            >
            </el-table-column>
            <el-table-column
              width="150"
              label="转化配置"
              align="center"
            >
              <template slot-scope="{ row }">
                <el-popover
                  placement="left"
                  trigger="hover"
                  v-if="row.adaccount.isSetup && row.adaccount.ctsCtStatus != 'CONVERSION_TRACKING_MANAGED_BY_SELF'"
                >
                  <div class="tip-wrap">
                    <div
                      v-for="(item, index) in configurationTips"
                      :key="index"
                    >
                      <p class="">
                        {{ item.tip }}
                      </p>
                      <el-image
                        class="tip-image"
                        :src="item.imgUrl"
                        :preview-src-list="configurationTips.map((item) => item.imgUrl)"
                      ></el-image>
                    </div>
                  </div>
                  <div slot="reference">
                    <span
                      v-if="row.adaccount.ctsCtStatus != 'CONVERSION_TRACKING_MANAGED_BY_SELF'"
                      class="color-warning"
                      >未生效 <i class="el-icon-question"></i
                    ></span>
                  </div>
                </el-popover>
                <span
                  class="color-success"
                  v-else-if="row.adaccount.isSetup"
                >
                  已配置{{ getOriginLabel(row.adaccount.utmSourceConfig) }}
                </span>
                <span v-else>未配置 </span>
              </template>
            </el-table-column>
            <el-table-column
              width="200"
              label="操作"
              fixed="right"
            >
              <template slot-scope="{ row }">
                <div>
                  <el-button
                    size="small"
                    @click="settingAccount(row)"
                    type="text"
                  >
                    账户设置
                  </el-button>
                  <el-button
                    size="small"
                    type="text"
                    @click="setSetupAccount(row.adaccount.id, row.adaccount)"
                    >转化配置</el-button
                  >
                  <el-button
                    size="small"
                    type="text"
                    @click="returnResult(row.adaccount.id, row.adaccount)"
                    >回传结果</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination">
            <el-pagination
              :current-page="page"
              :page-sizes="[10, 20, 30, 50, 100]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </el-main>
      </el-container>
    </el-container>

    <account-setting
      :account="currentAccount"
      :tags="tags"
      :show.sync="showAccountSetting"
      @confirm="confirmSetAccount"
    ></account-setting>
    <batch-setting
      :tags="tags"
      :show.sync="showBatchSetting"
      @confirm="confirmSetBatch"
    ></batch-setting>
    <!--    <conversion-dialog-->
    <!--        :show.sync="showConversion"-->
    <!--        :events="conversionEvents"-->
    <!--        @confirm="confirmConversion"-->
    <!--    ></conversion-dialog>-->
    <!-- 创建广告 -->
    <my-dialog :myDialogObj="createVisible">
      <create-gg-ad
        @closeCreate="f_closeCreate"
        :accountId="accountIdCreate"
        v-if="createVisible.title.indexOf('创建') > -1"
        @changeAdTitles="f_changeAdTitles"
      ></create-gg-ad>
    </my-dialog>
    <!-- 转化设置 -->
    <setupAccount
      :visible="setupAccountVisible"
      @toggleVisible="toggleSetupAccountVisible"
      :account_ids="account_ids"
      :isOne="isOne"
      @success="getList"
      :settinginfo="settinginfo"
    ></setupAccount>
    <!-- 回传结果 -->
    <return-result
      :returnResultShow.sync="returnResultShow"
      :account="resultAccount"
    ></return-result>
  </div>
</template>
<script>
// 创建广告
import createGgAd from '@/views/google/createGgAd/index.vue';
import MyDialog from '@/views/google/createGgAd/common/myDialog/index.vue';
import clipboard from '@/utils/clipboard.js';
import { tableHeight } from '@/utils/utils.js';
import {
  searchAccountNew,
  batchUpdate,
  getTags,
  mapConversionActions,
  initAdaccount,
  initAdaccountConversionOrder,
  hideCredentialAccount,
} from '@/api/google/account.js';
import { adConstsMapGG } from '@/api/google/ceateAdGg.js';
import { supportCreateAd } from '@/api/google/create';
import { getAuthUrl } from '@/api/google/cashVoucher.js';
import accountSetting from './components/accontSetting.vue';
import batchSetting from './components/batchSetting.vue';
import conversionDialog from './components/conversionDialog.vue';
import accountTree from '@/components/google/accountTree.vue';
import setupAccount from './components/setupAccount'; // 转化设置
import returnResult from './components/returnResult.vue';
export default {
  components: {
    accountSetting,
    batchSetting,
    conversionDialog,
    accountTree,
    createGgAd,
    MyDialog,
    setupAccount,
    returnResult,
  },
  data() {
    return {
      keyword: '',
      accountTree: [],
      treeProps: {
        children: 'zones',
        label: 'name',
        isLeaf: 'canNotManagerClient',
      },
      tags: [],
      createVisible: {
        title: '选择广告类型',
        dialogVisible: false,
        width: '1100px',
        top: '5vh',
      },
      form: {},
      accountIdCreate: [], //传给新增的accountId
      accountId: [],
      // 设置高度需要的数据
      tableHeightData: [
        { selector: '.navbar', cssProperty: 'height' },
        { selector: '.app-main', cssProperty: 'paddingTop' },
        { selector: '.app-main', cssProperty: 'paddingBottom' },
        { selector: '.el-header', cssProperty: 'height' },
        { selector: '.el-main', cssProperty: 'paddingTop' },
        { selector: '.el-main', cssProperty: 'paddingBottom' },
        { selector: '.button-groups', cssProperty: 'height' },
        { selector: '.pagination', cssProperty: 'height' },
      ],
      list: [],
      multipleSelection: [],
      page: 1,
      pageSize: 10,
      total: 0,
      tableHeight: 400,
      showAccountSetting: false,
      currentAccount: {},
      showBatchSetting: false,
      conversionAccount: {},
      conversionEvents: [],
      showConversion: false,
      setupAccountVisible: false, // 转化配置探矿
      account_ids: [], // 批量转化设置操作的account_ids
      isOne: false, //单个转化配置
      configurationTips: [
        {
          tip: '第一步：确保当前账户是自己管理的，请登录谷歌，在设置-->子账户设置中将Google Ads转化账户设置为客户',
          imgUrl: require('@/assets/image/configurationStep0.png'),
        },
        {
          tip: '第二步：如已设置请在谷歌系统-->凭证管理中同步账户即可',
          imgUrl: require('@/assets/image/configurationStep1.png'),
        },
      ],
      settinginfo: {}, //转化配置回显
      returnResultShow: false, //回传结果回显
      resultAccount: null, //点击回传结果当前账户
    };
  },
  mounted() {
    this.getTableHeight();
    this.accountId = [];
    this.reset();
    this.getTags();
    this.f_getConstData();
  },
  methods: {
    getOriginLabel(value) {
      const originMap = {
        GMALL3: '(商城3.0)',
        SHOP_PLUS: '(商城Plus)',
        SHOPLINE: '(shopline)',
        SHOPIFY: '(shopify)',
      };
      return originMap[value] || '';
    },
    //   获取常量
    async f_getConstData() {
      let res = await adConstsMapGG();
      if (res.code == 0) {
        let array = this.f_callToActionData(res.data);
        let data = res.data;
        data.defaulcallToActionData = array;
        this.$store.commit('initData/setConstDataGoogle', data);
      }
    },
    f_callToActionData(res) {
      let data = res.defaultCountries;
      let dataCallToAction = res.defaultCallToActions;
      if (data && data.length > 0) {
        // 过滤出可用的国家
        let arrayCountry = data.map((item) => item.defaultLocalCode);
        dataCallToAction.map((c) => {
          if (arrayCountry.includes(c.locale)) {
            let k = arrayCountry.indexOf(c.locale);
            data[k].shopNow = c.shopNow;
          }
        });
      }

      return data.filter((item) => item.shopNow);
    },
    // 改变创建广告的标题
    f_changeAdTitles(t) {
      this.createVisible.title = `创建${t}广告`;
    },
    // 获取标签列表
    getTags() {
      getTags().then((res) => {
        this.tags = res.data;
      });
    },
    // 账户树选择发生变化
    checkChange(b) {
      this.accountId = b;
      this.onSearch();
    },
    // 搜索
    onSearch() {
      this.page = 1;
      this.getList();
    },
    hideCredentialAccount(hide) {
      let section = this.multipleSelection;
      const list = section.map((v) => {
        return v.id;
      });
      this.$showLoading();
      hideCredentialAccount({
        ids: list,
        hide: hide,
      })
        .then((res) => {
          this.$hideLoading();
          if (res.code == 0) {
            this.$message.success('设置成功');
            section.forEach((v) => {
              v.hide = hide;
            });
          } else {
            this.$message.error('错误：' + res.comment);
          }
        })
        .catch((err) => {
          this.$hideLoading();
        });
    },
    // 重置搜索
    reset() {
      this.form = {};
      this.onSearch();
    },
    // 获取账户列表
    getList() {
      const { page, pageSize, form, accountId } = this;
      const data = {
        page,
        pageSize,
        accountIds: accountId,
        parentMccid: null,
        ...form,
        searchDetail: true,
      };
      this.$showLoading();
      searchAccountNew(data)
        .then((res) => {
          this.$hideLoading();
          if (typeof res === 'string') {
            res = JSON.parse(res);
          }
          if (res.code === 0) {
            this.list = res.data.list.map((item) => {
              return {
                adaccount: {
                  name: item.accountName, // 广告账户名称 accountName
                  canManagerClient: item.canManagerClient, // 是否可管理客户 canManagerClient
                  id: item.accountId, // 广告账户id accountId
                  currency: item.currency, // 货币 currency
                  timezoneName: item.timezoneName, // 时区 timezoneName
                  pullTime: item.pullTime, // 更新时间 pullTime
                  tags: item.tags || [], // 标签 tags
                  domains: item.domains, // 主域名 domains
                  isSetup: item.hasSetup, // 是否已设置 isSetup
                  ctsCtStatus: item.ctsCtStatus, // ctsCtStatus
                  utmSourceConfig: item.utmSourceConfig, //配置
                },
                invalid: item.invalid, // 是否无效  invalid
                error: item.error, // 错误信息 error
                hide: item.hide, // 是否隐藏 hide
                rootMccId: item.rootMccId, // 根MCCID rootMccId
                credential: {
                  thirdUser: {
                    thirdUserEmail: item.thirdUserName, // 第三方用户邮箱 thirdUserEmail
                  },
                },
              };
            });
            this.total = res.data?.total;
          }
        })
        .catch((err) => {
          this.$hideLoading();
        });
    },
    //复制
    clipboard(text) {
      clipboard(text)
        .then((res) => {
          this.$message.success('复制成功');
        })
        .catch((err) => {
          this.$message.error('浏览器不支持复制');
        });
    },
    // 改变一页显示几条
    handleSizeChange(val) {
      this.pageSize = val;
      this.onSearch();
    },
    // 翻页
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    // 表格多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 表格高度
    async getTableHeight() {
      await this.$nextTick();
      if (this.tableHeightData.length) {
        this.tableHeight = tableHeight(this.tableHeightData);
      }
    },
    // 账户设置弹框
    settingAccount(item) {
      console.log(item);
      this.currentAccount = item.adaccount;
      this.showAccountSetting = true;
    },
    // 创建广告
    async createAd(account) {
      // todo 创建广告
      if (account.utmSourceConfig == 'SHOPIFY') {
        this.$message({
          type: 'warning',
          message: 'shopify 账户不支持创建广告哦',
        });
        return;
      }
      //   检测凭证
      const res = await supportCreateAd({ accountId: account.id });
      if (!res.data) {
        this.$confirm('没有可用的创建广告的凭证，是否立即创建？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            getAuthUrl({ oauthClientType: 'GOOGLE_ADS', createOnly: true }).then((res) => {
              window.open(res.data);
            });
          })
          .catch(() => {});
        return;
      }
      this.$store.commit('initData/setAccountIdGoogle', account.id);
      this.$store.commit('google/setCreateAdAccount', account);
      this.accountIdCreate = [account.id];
      this.$router.push({
        name: 'createGoogle',
      });
      //   this.createVisible = {
      //     top: '5vh',
      //     width: '1100px',
      //     title: '创建发现广告',
      //     dialogVisible: true,
      //   };
    },
    // 确认修改单个账户
    confirmSetAccount(form) {
      const data = {
        ...form,
        accountId: this.currentAccount.id,
      };
      this.batchUpdate([data]);
    },
    // 确认批量修改
    confirmSetBatch(form) {
      const list = this.multipleSelection.map((v) => {
        return {
          accountId: v.adaccount.id,
          ...form,
        };
      });
      this.batchUpdate(list);
    },
    // 更新
    batchUpdate(updates) {
      this.$showLoading();
      batchUpdate({
        updates,
      })
        .then((res) => {
          this.$hideLoading();
          if (res.code == 0) {
            this.$message.success('设置成功');
            this.showAccountSetting = false;
            this.showBatchSetting = false;
            this.getList();
          }
        })
        .catch((err) => {
          this.$hideLoading();
        });
    },

    // confirmConversion(list) {
    //   const data = {
    //     accountId: this.conversionAccount.adaccount.id,
    //     mapItems: list,
    //   };
    //   this.$showLoading();
    //   mapConversionActions(data)
    //       .then((res) => {
    //         this.showConversion = false;
    //         this.$hideLoading();
    //         this.$message.success("同步成功");
    //         this.getList();
    //       })
    //       .catch((err) => {
    //         this.$hideLoading();
    //       });
    // },
    initAdaccount(row) {
      if (!row.adaccount.isSetup) {
        this.$showLoading();
        initAdaccount({ accountId: row.adaccount.id })
          .then((res) => {
            this.$hideLoading();
            this.$message.success('初始化成功');
            this.getList();
          })
          .catch((err) => {
            this.$hideLoading();
          });
      }
    },
    initAdaccountConversionActionOrder(row) {
      if (row.adaccount.isSetup && !row.adaccount.conversionActionOrder) {
        this.$showLoading();
        initAdaccountConversionOrder({ accountId: row.adaccount.id })
          .then((res) => {
            this.$hideLoading();
            this.$message.success('成功');
            this.getList();
          })
          .catch((err) => {
            this.$hideLoading();
          });
      }
    },
    showHide(row, isHide) {
      if (isHide == row.adaccount.hide) {
        return;
      }
      const data = {
        accountId: row.adaccount.id,
        hide: isHide,
      };
      this.$showLoading();
      batchUpdate({ updates: [data] })
        .then((res) => {
          this.$hideLoading();
          if (res.code == 0) {
            this.$message.success('设置成功');
            this.showAccountSetting = false;
            this.showBatchSetting = false;
            row.adaccount.hide = isHide;
          }
        })
        .catch((err) => {
          this.$hideLoading();
        });
    },

    f_goChangename(row) {
      this.$prompt(null, '请输入新账号名称', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /.+/,
        inputErrorMessage: '格式不正确',
        inputValue: row.adaccount.name,
      })
        .then(({ value }) => {
          this.f_submitChangename(row, value);
        })
        .catch(() => {});
    },
    f_submitChangename(row, newName) {
      if (!newName || newName == row.adaccount.name) {
        return;
      }
      let data = {
        updates: [
          {
            accountId: row.adaccount.id,
            name: newName,
          },
        ],
      };
      this.$showLoading();
      batchUpdate(data)
        .then((res) => {
          this.$hideLoading();
          if (res.code == 0) {
            this.$message.success('设置成功');
            row.adaccount.name = newName;
          } else {
            this.$message.error('设置失败：' + res.comment);
          }
        })
        .catch((err) => {
          this.$message.error('设置失败');
          this.$hideLoading();
        });
    },
    // 转化设置显示隐藏
    toggleSetupAccountVisible(bool = false) {
      this.setupAccountVisible = bool;
    },
    setSetupAccount(id, msg) {
      this.isOne = !!id;
      if (this.isOne) {
        this.account_ids = [id];
        this.settinginfo = { ...msg };
      } else {
        this.account_ids = this.multipleSelection.map((item) => item.adaccount.id);
      }
      this.toggleSetupAccountVisible(true);
    },
    // 回传结果
    returnResult(id, account) {
      if (!account.utmSourceConfig) {
        this.$message({
          type: 'warning',
          message: '该账户未进行转化回传配置',
        });
        return;
      }
      this.returnResultShow = true;
      this.resultAccount = account;
    },
    f_closeCreate() {
      console.log('-----f_closeCreate');
      this.$set(this.createVisible, 'dialogVisible', false);
    },
  },
};
</script>
<style lang="scss" scoped>
.container1 {
  .aside {
    position: relative;

    &-content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
    }

    .title {
      font-size: 18px;
      height: 60px;
      line-height: 60px;
      text-indent: 18px;
      border-bottom: 1px solid #efefef;
      margin: 0;
    }

    .account-tree {
      padding-top: 4px;
      flex: 1;
      overflow: hidden;
    }

    ::v-deep .el-tree-node__content {
      align-items: flex-start;
      padding: 4px 0;
      padding-right: 8px;
      height: auto;

      .el-tree-node__label {
        word-break: break-all;
        white-space: break-spaces;
      }
    }
  }

  .flex-btw {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .button-groups {
    padding-bottom: 10px;
  }

  .search-wrap {
    padding: 10px;
  }

  .form-inline {
    padding-top: 13px;

    ::v-deep .el-form-item {
      margin-bottom: 0;
    }
  }

  .pagination {
    margin-top: 10px;
  }

  .el-icon-edit {
    color: #409eff;
    margin-left: 5px;
  }

  .table-tags {
    ::v-deep .el-tag {
      margin-right: 8px;
    }
  }

  .init-btn {
    cursor: pointer;

    &:hover {
      font-size: 16px;
    }
  }
}
.tip-wrap {
  padding: 20px;
  max-height: 80vh;
  overflow-y: scroll;
  p {
    font-size: 20px;
    font-weight: 600;
    margin: 20px 0;
  }
}
.tip-image {
  width: 1000px;
}
.link {
  .el-link.el-link--default {
    font-size: 12px;
    color: #888;
    margin-right: 10px;
    &:hover {
      color: #409eff;
    }
  }
}
</style>
<style lang="scss">
.domain-list {
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 5px;
  }

  .el-icon-document-copy {
    color: #409eff;
    cursor: pointer;
  }
}

.el-table__body-wrapper {
  &::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
  }
}
</style>
